import { defaultDevtoolsOptions } from '@/config/zustand';
import { EnvConfig } from '@/types/common.types';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface EnvConfigParams extends EnvConfig {
  country: string;
  defaultCountry?: string;
  authorizedCountries: string[];
  bearerToken: string;
}

const initialState: EnvConfigParams = {
  name: '',
  SUPPORTED_COUNTRIES: [],
  authorizedCountries: [],
  country: '',
  bearerToken: '',
  OPTIMIZELY_KEY: '',
};

export interface EnvStore extends EnvConfigParams {
  setEnv: (env: Partial<EnvConfigParams>) => void;
  setCountry: (country: EnvConfigParams['country']) => void;
  setAuthorizedCountry: (authorizedCountries: EnvConfigParams['authorizedCountries']) => void;
}

const persistOptions = {
  name: 'customizationEnvValues',
  storage: createJSONStorage(() => localStorage),
};

const useEnvStore = create<EnvStore>()(
  persist(
    devtools(
      (set, get) => ({
        ...initialState,
        setEnv: (env) => set({ ...get(), ...env, country: get().country || env.defaultCountry }),
        setCountry: (country) => set({ country }),
        setAuthorizedCountry: (authorizedCountries) => set({ authorizedCountries }),
      }),
      { ...defaultDevtoolsOptions, anonymousActionType: 'useEnvStore' },
    ),
    persistOptions,
  ),
);

export default useEnvStore;
