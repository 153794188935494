export const APP_ID = 'COUPON_MANAGEMENT';
export const APP_ENTITY = 'COUPON_CAMPAIGNS';

export const defaultDevtoolsOptions = {
  name: 'Promotions',
  enabled: localStorage.getItem('devtools') === 'true',
};


export const ROUTER_BASENAME = '/promotion';

export const PROMOTION_ROUTES = {
  HOME: ROUTER_BASENAME,
  COUPON_MANAGEMENT: `${ROUTER_BASENAME}/management`,
  CREATE_COUPON: `${ROUTER_BASENAME}/management/create/:country`,
  DELETED_COUPON: `${ROUTER_BASENAME}/management/deleted`,
  DETAILS_COUPON: `${ROUTER_BASENAME}/management/:id`,
  MGM: `${ROUTER_BASENAME}/mgm`,
  MGM_REFERRAL_COUPON: `${ROUTER_BASENAME}/mgm/rc`,
  MGM_REWARD_COUPON: `${ROUTER_BASENAME}/mgm/rwc`,
  MGM_MANAGE: `${ROUTER_BASENAME}/mgm/manage`,
  MGM_BLOCKLIST: `${ROUTER_BASENAME}/mgm/manage/blocklist`,
};
