import { PROMOTION_ROUTES } from '@/config/constants';
import { Home } from '@hexa-ui/icons';
import { useSidebar as useSideBarService } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';

export const HomeIcon = (): React.ReactElement => <Home />;

const useSidebar = () => {
  const { formatMessage } = useIntl();

  const items = [
    {
      id: 'home',
      title: formatMessage({ id: 'SIDE_MENU.HOME' }),
      icon: HomeIcon,
      path: PROMOTION_ROUTES.HOME,
    },
  ];

  return useSideBarService({ items, utils: [] });
};

export default useSidebar;
